<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed text v-bind="attrs" v-on="on" color="primary"
        >Exportação de Dados</v-btn
      >
    </template>
    <v-list>
      <v-list-item link>
        <v-list-item-title @click="fnExportCSV"
          >Convites UBAs (.csv)</v-list-item-title
        >
      </v-list-item>
      <v-list-item link>
        <v-list-item-title @click="fnExportExcel"
          >Convites UBAs (.xlsx)</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "ExportDataUBA",

  props: {
    fnExportCSV: {
      type: Function,
      required: true
    },
    fnExportExcel: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
