<template>
  <div>
    <h3 class="my-4 primary-text--text">Cancelar convite</h3>

    <v-row class="mb-8" v-if="detail.pode_visualizar_botao_cancelar_convite">
      <v-col sm="2">
        <optional-tooltip
          :hide="detail.pode_solicitar_cancelamento_convite"
          text="Já existe uma Solicitação de Cancelamento realizada."
        >
          <v-btn
            :disabled="!detail.pode_solicitar_cancelamento_convite"
            outlined
            block
            color="error"
            @click="handleOpenDialogRequestReviewCancelInvite()"
            >Solicitar cancelamento deste convite</v-btn
          >
        </optional-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="detail.solicitacao_cancelamento">
      <v-col sm="6">
        <v-row>
          <v-col cols="6" sm="6">
            <div class="d-flex flex-column">
              <p
                class="ma-0 secondary-text--text"
                v-if="detail.solicitacao_cancelamento.cancelado_via_integracao"
              >
                Responsável
              </p>
              <p class="ma-0 secondary-text--text" v-else>
                {{ $t("labels.requester") }}
              </p>
              <p class="ma-0 primary-text--text text-uppercase">
                <strong>
                  {{
                    detail.solicitacao_cancelamento.solicitado_por ||
                      defaultEmptyData
                  }}
                </strong>
              </p>
            </div>
          </v-col>
          <v-col cols="6" sm="6">
            <div class="d-flex flex-column">
              <p
                class="ma-0 secondary-text--text"
                v-if="detail.solicitacao_cancelamento.cancelado_via_integracao"
              >
                Data do canceladomento
              </p>
              <p class="ma-0 secondary-text--text" v-else>
                {{ $t("labels.datetime_type", { type: "Solicitação" }) }}
              </p>
              <p class="ma-0 primary-text--text">
                {{
                  detail.solicitacao_cancelamento.cadastrado_em_formatado ||
                    defaultEmptyData
                }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <div class="d-flex flex-column">
              <p
                class="ma-0 secondary-text--text"
                v-if="detail.solicitacao_cancelamento.cancelado_via_integracao"
              >
                Motivo do canceladomento
              </p>
              <p class="ma-0 secondary-text--text" v-else>
                {{ $t("labels.reason_request") }}
              </p>
              <p class="ma-0 primary-text--text text-uppercase">
                {{ detail.solicitacao_cancelamento.motivo || defaultEmptyData }}
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row
          v-if="
            !detail.solicitacao_cancelamento.cancelado_via_integracao &&
              detail.solicitacao_cancelamento.parecer_por
          "
        >
          <v-col cols="6" sm="6">
            <div class="d-flex flex-column">
              <p class="ma-0 secondary-text--text">
                {{ $t("labels.responsible_opnion") }}
              </p>
              <p class="ma-0 primary-text--text text-uppercase">
                <strong>
                  {{
                    detail.solicitacao_cancelamento.parecer_por ||
                      defaultEmptyData
                  }}
                </strong>
              </p>
            </div>
          </v-col>

          <v-col cols="6" sm="6">
            <div class="d-flex flex-column">
              <p class="ma-0 secondary-text--text">
                {{ $t("labels.datetime_type", { type: "parecer" }) }}
              </p>
              <p class="ma-0 primary-text--text">
                {{
                  detail.solicitacao_cancelamento.atualizado_em_formatado ||
                    defaultEmptyData
                }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        sm="6"
        v-if="!detail.solicitacao_cancelamento.cancelado_via_integracao"
      >
        <div class="d-flex flex-column">
          <div>
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.status", {}) }}
            </p>
            <status :value="detail.solicitacao_cancelamento.status.id">
              <strong class="primary-text--text text-uppercase">
                {{ detail.solicitacao_cancelamento.status.descricao }}
              </strong>
            </status>
          </div>

          <div v-if="detail.pode_visualizar_botao_cancelar_convite">
            <v-row>
              <v-col sm="4">
                <v-btn
                  v-if="detail.pode_revisar_solicitacao_cancelamento"
                  outlined
                  block
                  color="primary"
                  @click="handleOpenDialogRequestReviewCancelInvite()"
                  >Solicitar revisão de status</v-btn
                >
                <v-btn
                  v-if="detail.pode_cancelar_solicitacao_cancelamento"
                  outlined
                  block
                  color="error"
                  @click="handleCancelCancelInvite"
                  >Cancelar Solicitação</v-btn
                >
              </v-col>
            </v-row>
          </div>

          <div v-if="detail.pode_realizar_parecer_solicitacao_cancelamento">
            <v-row>
              <v-col sm="4">
                <v-btn
                  outlined
                  block
                  color="error"
                  @click="handleSendNotion(false)"
                  >Reprovar Solicitação</v-btn
                >
              </v-col>
              <v-col sm="4">
                <v-btn
                  outlined
                  block
                  color="success"
                  @click="handleSendNotion(true)"
                  >Aprovar Solicitação</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <dialog-request-review-cancel-invite
      @closeDialogRequestReviewCancelInvite="
        onCloseDialogRequestReviewCancelInvite()
      "
      v-if="shouldRenderDialogRequestReviewCancelInvite"
      :status="statusCancelInvite"
      :fnConfirm="handleConfirmCancelOrReviewRequest"
      :mode="dialogRequestReviewCancelInviteMode"
      ref="dialogRequestReviewCancelInvite"
      :key="`dialog-request-review-${dialogRequestReviewKey}`"
    />
  </div>
</template>

<script>
import Status from "@/modules/inviteds/views/components/Status";
import DialogRequestReviewCancelInvite from "@/modules/invite-uba/views/components/manage-invite/DialogRequestReviewCancelInvite";

import { modesAvaliable } from "@/modules/invite-uba/views/components/manage-invite/DialogRequestReviewCancelInvite.vue";
import { Profile } from "@/modules/inviteds/enums/profile.enum";

import OptionalTooltip from "@/modules/core/views/components/OptionalTooltip";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CancelInvite",

  props: {
    detail: {
      type: Object,
      required: true
    },
    fnRefetch: {
      type: Function,
      required: true
    }
  },

  components: {
    Status,
    DialogRequestReviewCancelInvite,
    OptionalTooltip
  },

  data: () => ({
    defaultEmptyData: "Não registrado",
    dialogRequestReviewKey: 1
  }),

  computed: {
    ...mapGetters({
      user: "inviteds/getUser"
    }),

    userIsAbrapa() {
      return Profile.ABRAPA.check(this.user?.profile?.id);
    },

    userIsEstadual() {
      return Profile.STATE.check(this.user?.profile?.id);
    },

    statusCancelInvite() {
      return this.detail?.solicitacao_cancelamento?.status;
    },

    dialogRequestReviewCancelInviteMode() {
      if (this.detail.pode_revisar_solicitacao_cancelamento) {
        return modesAvaliable.REVIEW;
      }

      return modesAvaliable.CANCEL;
    },

    showButtonReviewInvite() {
      return this.dialogRequestReviewCancelInviteMode === modesAvaliable.REVIEW;
    },

    // TODO: Este botão deve ser desabilitado se já houver algum histórico
    // de solicitação de alteração de Status
    showButtonCancelInvite() {
      return this.dialogRequestReviewCancelInviteMode === modesAvaliable.CANCEL;
    },

    shouldRenderDialogRequestReviewCancelInvite() {
      return (
        this.detail.solicitacao_cancelamento ||
        this.detail.pode_solicitar_cancelamento_convite
      );
    }
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("inviteUba", [
      "cancelInviteUBA",
      "sendNotionCancelInviteUBA",
      "cancelCancelInviteUBA"
    ]),

    handleOpenDialogRequestReviewCancelInvite() {
      if (!this.$refs?.dialogRequestReviewCancelInvite?.open) {
        throw new Error(
          "Failed to open dialog, missing $refs or function open"
        );
      }
      this.$refs.dialogRequestReviewCancelInvite.open();
    },

    handleConfirmCancelOrReviewRequest(mode, formData) {
      this.handleCancelInvite(formData);
    },

    async handleCancelInvite(formData) {
      try {
        const response = await this.cancelInviteUBA({
          id: this.detail.id,
          body: {
            motivo: formData.justification
          }
        });

        this.toggleSnackbar({
          text: response?.detail ?? "Solicitação de cancelamento enviada!",
          type: "success"
        });

        await this.fnRefetch();
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    handleSendNotion(accepted) {
      const action = accepted ? "Aprovar" : "Reprovar";
      const message = `Você tem certeza que deseja ${action} esta solicitação de Cancelamento de Convite para esta UBA?`;
      this.$confirmDialog.open(message, () => this.callSendNotion(accepted));
    },

    async callSendNotion(accepted) {
      try {
        const response = await this.sendNotionCancelInviteUBA({
          id: this.detail.id,
          body: {
            aceitou: accepted
          }
        });

        this.toggleSnackbar({
          text: response?.detail ?? "Parecer salvo com sucesso!",
          type: "success"
        });

        this.fnRefetch();
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    handleCancelCancelInvite() {
      const message = `Você tem certeza que deseja cancelar esta solicitação de cancelamento de convite ABR-UBA?`;
      this.$confirmDialog.open(message, () => this.callCancelCancelInviteUBA());
    },

    async callCancelCancelInviteUBA() {
      try {
        const response = await this.cancelCancelInviteUBA(this.detail.id);

        this.toggleSnackbar({
          text: response?.detail ?? "Soliciação cancelada com sucesso!",
          type: "success"
        });

        this.fnRefetch();
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    onCloseDialogRequestReviewCancelInvite() {
      this.dialogRequestReviewKey++;
    }
  }
};
</script>

<style lang="scss" scoped></style>
