<template>
  <div>
    <div v-if="canManageInvite">
      <div class="d-flex justify-center">
        <btn-link @click="handleOpenDialogManageInvite">Gerenciar</btn-link>
      </div>
      <dialog-manage-invite ref="dialogManageInvite" />
    </div>
    <div v-else>
      <div class="d-flex justify-center">
        <btn-link @click="handleAnswerInvite">Responder Convite</btn-link>
      </div>
    </div>
  </div>
</template>

<script>
import DialogManageInvite from "@/modules/invite-uba/views/components/manage-invite/DialogManageInvite";
import BtnLink from "@/modules/core/views/components/BtnLink";
import { mapGetters } from "vuex";

import { Profile } from "@/modules/inviteds/enums/profile.enum";
import { InviteStatus } from "@/modules/core/enums/invite-status.enum";

export default {
  name: "ActionsInvitesUBA",

  props: {
    detail: {
      type: Object,
      required: true
    }
  },

  components: {
    BtnLink,
    DialogManageInvite
  },

  computed: {
    ...mapGetters({ user: "inviteds/getUser" }),

    canManageInvite() {
      if (Profile.INVITED.check(this.user.profile.id)) {
        return !InviteStatus.INVITE_SENT.check(this.detail.status?.id);
      }
      return [Profile.STATE.id, Profile.ABRAPA.id].includes(
        this.user.profile.id
      );
    }
  },

  methods: {
    handleOpenDialogManageInvite() {
      if (!this.$refs?.dialogManageInvite?.open) {
        throw new Error(
          "Failed to open dialog, missing $refs or function open"
        );
      }
      this.$refs.dialogManageInvite.open(this.detail.id);
    },

    handleAnswerInvite() {
      this.$router.push({
        name: "answer-invite",
        params: { id: this.detail.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
