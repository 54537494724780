<template>
  <v-dialog
    v-model="modalState"
    :persistent="true"
    :retain-focus="false"
    origin="center left"
    width="95%"
    scrollable
  >
    <v-card class="modal">
      <v-card-title class="primary--text">Gerenciar Convite</v-card-title>

      <v-divider />

      <v-card-text>
        <div class="mb-4">
          <h3 class="my-4 primary-text--text">
            Dados de cadastro da Unidade de Beneficiamento de Algodão
          </h3>

          <productive-unit-detail :productiveUnit="detail.algodoeira" />
        </div>

        <v-divider />

        <div class="mb-4">
          <h3 class="my-4 primary-text--text">
            Dados do Convite ao Programa ABR-UBA
          </h3>
          <invite-detail :invite="detail" />

          <v-row>
            <v-col
              v-if="detail.pode_visualizar_botao_reenviar_email_convite"
              cols="3"
              align-self="center"
            >
              <v-btn
                :disabled="!detail.pode_reenviar_email_convite"
                outlined
                block
                color="primary"
                @click="onResendEmailInvite()"
                >Reenviar e-mail de Convite</v-btn
              >
            </v-col>
            <v-col
              v-if="detail.pode_fazer_download_termo"
              cols="3"
              align-self="center"
            >
              <v-btn outlined block color="primary" @click="onDownloadTerm()"
                >Download do termo de adesão</v-btn
              >
            </v-col>
          </v-row>

          <div class="mt-4">
            <h4>Responsáveis Convidados</h4>

            <table-responsibles
              class="mb-4"
              :responsibles="detail.convidados"
              :showPhones="true"
              @removeResponsible="prepareRemoveResponsible($event)"
              @editResponsible="handleEditResponsible($event)"
            />

            <v-divider />

            <note class="mt-4">
              Caso deseje convidar um novo Responsável ABR-UBA para esta Unidade
              de Beneficiamento, realize o processo de convite normalmente. O
              status do convite não será alterado.
            </note>
          </div>
        </div>

        <v-divider />

        <div class="mb-4">
          <h3 class="my-4 primary-text--text">
            Dados do(s) Proprietário(s)
          </h3>

          <owner-detail
            :owners="detail.algodoeira.proprietarios"
            :socialName="detail.algodoeira.razao_social"
          />
        </div>

        <div
          v-if="
            detail.pode_visualizar_botao_cancelar_convite ||
              detail.solicitacao_cancelamento
          "
        >
          <v-divider />

          <cancel-invite :detail="detail" :fnRefetch="refetch" />
        </div>

        <v-divider />
      </v-card-text>

      <v-divider />

      <v-card-actions class="d-flex justify-center align-center">
        <v-btn text color="primary" @click="close">{{
          $t("labels.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductiveUnitDetail from "@/modules/invite-uba/views/components/manage-invite/ProductiveUnitDetail";
import OwnerDetail from "@/modules/invite-uba/views/components/new-invite/OwnerDetail";
import InviteDetail from "@/modules/invite-uba/views/components/manage-invite/InviteDetail";
import Note from "@/modules/core/views/components/Note";
import TableResponsibles from "@/modules/invite-uba/views/components/manage-invite/TableResponsibles";

import CancelInvite from "@/modules/invite-uba/views/components/manage-invite/CancelInvite";

import GridMixin from "@/modules/core/mixins/gridMixin";
import { mapActions } from "vuex";

export default {
  name: "DialogManageInvite",

  mixins: [GridMixin],

  components: {
    OwnerDetail,
    ProductiveUnitDetail,
    InviteDetail,
    Note,
    TableResponsibles,
    CancelInvite
  },

  data: () => ({
    defaultEmptyData: "Não registrado",
    modalState: false,
    detail: {
      algodoeira: {
        nome: undefined,
        razao_social: undefined,
        cpf_cnpj: undefined,
        municipio: undefined,
        codigo_gs1_digito_extensao: undefined,
        unidade_produtivas: [],
        categoria: undefined,
        safra_certificacao: undefined,
        proprietarios: []
      },
      status: {
        id: undefined,
        nome: undefined
      },
      convidados: [],
      pode_solicitar_cancelamento_convite: undefined,
      pode_cancelar_solicitacao_cancelamento: undefined,
      pode_revisar_solicitacao_cancelamento: undefined,
      pode_realizar_parecer_solicitacao_cancelamento: undefined,
      pode_reenviar_email_convite: undefined,
      pode_visualizar_botao_reenviar_email_convite: undefined,
      pode_fazer_download_termo: undefined
    },

    file: null
  }),

  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("invited", ["updateResponsible"]),
    ...mapActions("inviteUba", [
      "fetchInviteDetail",
      "getTermUBA",
      "resendInviteUBA",
      "deleteInvitedUba"
    ]),

    close() {
      this.modalState = false;
    },

    async open(id) {
      await this.fetchInvite(id);
      this.modalState = true;
    },

    async refetch() {
      await this.fetchInvite(this.detail.id);
    },

    async onResendEmailInvite() {
      await this.callFetchData(async () => {
        const response = await this.resendInviteUBA(this.detail.id);

        this.toggleSnackbar({
          text: response?.message ?? "E-mail reenviado com sucesso",
          type: "success"
        });
      });
    },

    onDownloadTerm() {
      window.open(this.file, "_blank");
    },

    async getTerms() {
      try {
        const id = parseInt(this.detail.id, 10);
        const response = await this.getTermUBA(id);
        if (response.file) {
          this.file = response.file;
        }
      } catch (error) {
        this.toggleSnackbar({
          text: error.response?.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    async fetchInvite(id) {
      await this.callFetchData(async () => {
        this.detail = await this.fetchInviteDetail(id);
        if (this.detail.pode_fazer_download_termo) {
          this.getTerms();
        }
      });
    },

    handleEditResponsible(responsible) {
      this.callFetchData(async () => {
        await this.updateResponsible({ ...responsible });
        await this.fetchInvite(this.detail.id);

        this.toggleSnackbar({
          text: this.$t("text.responsavel_alterado_sucesso"),
          type: "success"
        });
      });
    },

    prepareRemoveResponsible(responsible) {
      this.$confirmDialog.open(this.$t("labels.confirm_action"), () =>
        this.handleRemoveResponsible(responsible)
      );
    },

    handleRemoveResponsible(responsible) {
      this.callFetchData(async () => {
        await this.deleteInvitedUba({
          sendingId: this.detail.id,
          invitedId: responsible.id
        });

        await this.fetchInvite(this.detail.id);

        this.toggleSnackbar({
          text: this.$t("text.success_cancel_invite"),
          type: "success"
        });
      });
    },

    async callFetchData(fn) {
      try {
        await fn();
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
