import { mapActions } from "vuex";
import { saveAs } from "file-saver";

export default {
  methods: {
    ...mapActions(["toggleSnackbar"]),

    async downloadFromMemory(blob, name) {
      try {
        saveAs(blob, name);
      } catch (error) {
        this.toggleSnackbar({
          text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
          type: "error"
        });
      }
    }
  }
};
