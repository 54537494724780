<template>
  <div v-if="modalState">
    <v-dialog
      v-model="modalState"
      :persistent="true"
      :retain-focus="false"
      origin="center left"
      width="600"
      scrollable
    >
      <v-card class="modal">
        <v-card-title class="primary--text">
          {{ title }}
        </v-card-title>

        <v-divider />

        <v-card-text class="pt-4">
          <v-row v-if="showStatus">
            <v-col cols="6" sm="6">
              <div class="d-flex flex-column">
                <p class="ma-0 secondary-text--text">
                  {{ $t("labels.status", {}) }}
                </p>
                <status :value="status.id">
                  <strong class="primary-text--text">
                    {{ status.descricao }}
                  </strong>
                </status>
              </div>
            </v-col>
          </v-row>

          <v-form ref="form" v-model="form.isValid">
            <v-row cols="12">
              <v-col>
                <v-textarea
                  v-model="form.data.justification"
                  dense
                  :rules="form.rules.justification"
                  outlined
                  :label="$t('labels.reason_request')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-layout justify-end>
            <v-btn @click="close" class="mr-5" color="#FFF">{{
              $t("buttons.close")
            }}</v-btn>
            <v-btn
              type="submit"
              :disabled="!form.isValid"
              color="primary"
              @click="prepareConfirm"
              >{{ $t("buttons.send_request") }}</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Status from "@/modules/inviteds/views/components/Status";

export const modesAvaliable = {
  CANCEL: "cancel",
  REVIEW: "review"
};

export default {
  name: "DialogRequestReviewCancelInvite",

  props: {
    status: {
      type: Object,
      required: false
    },
    mode: {
      type: String,
      required: true,
      validate: mode => {
        return Object.values(modesAvaliable).includes(mode);
      }
    },
    fnConfirm: {
      type: Function,
      required: true
    }
  },

  components: {
    Status
  },

  data: () => ({
    modalState: false,

    form: {
      isValid: false,
      data: {
        justification: null,
        acceptTerms: false
      },
      rules: {
        justification: [
          value => !!value || "É necessário informar o motivo da solicitação"
        ],
        acceptTerms: [value => !!value || "É necessário confirmar esta ação"]
      }
    },

    showStatus: false,
    title: ""
  }),

  methods: {
    open() {
      this.initialize();
      this.modalState = true;
    },

    close() {
      this.$emit("closeDialogRequestReviewCancelInvite");
      this.modalState = false;
    },

    initialize() {
      if (this.mode === modesAvaliable.CANCEL) {
        this.showStatus = false;
        this.title = "Solicitar cancelamento deste Convite";
      } else if (this.mode === modesAvaliable.REVIEW) {
        this.showStatus = true;
        this.title =
          "Solicitar revisão da Solicitação de Cancelamento de Convite";
      }
    },

    prepareConfirm() {
      const action =
        this.mode === modesAvaliable.CANCEL ? "cancelamento" : "revisão";
      const message = `Você tem certeza que deseja solicitar ${action} de Convite para esta Unidade de Beneficiamento?`;
      this.$confirmDialog.open(message, this.handleClickConfirm);
    },

    handleClickConfirm() {
      this.close();
      this.fnConfirm(this.mode, { ...this.form.data });
    }
  }
};
</script>

<style lang="scss" scoped></style>
